.App {
  text-align: center;
  min-height: 100dvh;
  font-size: 20px;
  background-color: #282c34;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
}
main form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
main form select {
  font-size: 1em;
  padding: 10px;
  border-radius: 10px;
  background-color: transparent;
  color: white;
  border: 1px solid #b1f1ff;
  box-shadow: 0 0 10px #b1f1ff;
}
option {
  background-color: #282c34;
  color: white;
}
main form textarea {
  width: 90%;
  padding: 10px;
  margin: 10px;
  border: 1px solid #b1f1ff;
  color: white;
  border-radius: 10px;
  max-width: 500px;
  text-align: center;
  background-color: transparent;
  font-size: 1em;
  box-shadow: 0 0 10px #b1f1ff;
}
main form button {
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
  color: white;
  font-size: 1em;
  cursor: pointer;
  width: 80%;
  max-width: 350px;
  background: linear-gradient(45deg, #e6e7ed, #c4cbff, #ffffff);
}
main form button.loading {
  animation: sizeAnimation 350ms forwards;
}
@keyframes sizeAnimation {
  
  100% {
    width: 150px;
  }
}
#output-txt {
  width: 80%;
}
main form button:hover {
  background-color: #ffffff;
  color: black;
}
main form button:disabled {
  
  color: grey;
}

footer {
  height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 0.8em;
  position: absolute;
  bottom: 0;
  width: 100%;
  color: white;
}
::-webkit-scrollbar-track {
  background-color: #282c34;
}
::-webkit-scrollbar-thumb {
  background-color: #b1f1ff;
  border-radius: 10px;
  color: #b1f1ff;
}
::-webkit-scrollbar {
  width: 5px;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
